import { motion, useInView } from 'framer-motion';
import { Link, graphql } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import parse from 'html-react-parser';
import React, { useEffect, useRef, useState } from 'react';
import { Navigation, Pagination } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import { v4 as uuidv4 } from 'uuid';

const RelatedBlog = ({ module }) => {
  const ref = useRef(null);
  const isInView = useInView(ref);
  const [hasPlayed, setHasPlayed] = useState(false);
  const uniqueId = uuidv4();

  const container = {
    hidden: { opacity: 1 },
    show: {
      opacity: 1,
      transition: {
        duration: 0.5,
        ease: 'easeOut',
        delayChildren: 0.5,
        staggerChildren: 0.2,
      },
    },
  };

  const item = {
    hidden: { opacity: 0, y: 20 },
    show: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.8,
        ease: 'easeOut',
      },
    },
  };

  const container2 = {
    hidden: { opacity: 0, y: 20 },
    show: {
      opacity: 1,
      y: 0,
      transition: {
        delay: 0.8,
        duration: 0.8,
        ease: 'easeOut',
      },
    },
  };

  useEffect(() => {
    if (isInView && !hasPlayed) {
      setHasPlayed(true);
    }
  }, [isInView, hasPlayed]);

  return (
    <section
      className={`discover-blog py-60 bg-pattern${module?.extraClass ? ' ' + module.extraClass : ''
        }`}
    >
      <div className="container-fluid pr-0 fade-ani">
        <motion.div
          ref={ref}
          initial="hidden"
          animate={isInView || hasPlayed ? 'show' : 'hidden'}
          variants={container}
          className="section-title lgscreen:pr-20"
        >
          {module?.heading && (
            <motion.div variants={item} className="title-black">
              <h3>{module.heading}</h3>
            </motion.div>
          )}
          {module?.description && (
            <motion.div variants={item} className="content">
              {parse(module.description)}
            </motion.div>
          )}
        </motion.div>
        <motion.div
          initial="hidden"
          animate={isInView || hasPlayed ? 'show' : 'hidden'}
          variants={container2}
          id={`discover-blog-slider-row-${uniqueId}`} 
          className="discover-blog-slider discover-blog-slider-section relative overflow-hidden lg:mt-40 mt-20 fade-ani"
        >
          <Swiper
            spaceBetween={20}
            loop={true}
            slidesPerView={3.5}
            allowTouchMove={true}
            speed={1000}
            navigation={{
              nextEl: `.swiper-button-next-${uniqueId}`,
              prevEl: `.swiper-button-prev-${uniqueId}`,
            }}
            modules={[Pagination, Navigation]}
            className="h-full"
            breakpoints={{
              100: {
                slidesPerView: 1.2,
                spaceBetween: 20,
              },
              768: {
                slidesPerView: 2.5,
                spaceBetween: 20,
              },
              1024: {
                slidesPerView: 3.5,
                spaceBetween: 20,
              },
            }}
          >
            {module?.blog?.length > 0 &&
              module?.blog.map((item) => (
                <SwiperSlide key={uuidv4()}>
                  <Link to={item?.uri}>
                    <div className="db-bx bg-white min-h-[31.7vw] lgscreen:min-h-[44.7vw] mdscreen:min-h-[93.7vw]">
                      <GatsbyImage
                        className="hover:cursor-grab"
                        image={getImage(item?.featuredImage?.node)}
                        alt={item?.featuredImage?.node.altText}
                      />
                      <div className="db-content lg:py-30 py-15 lg:px-20 px-10 hover:cursor-pointer">
                        <div className="content">
                          <h6 className="uppercase text-19 lgscreen:text-16 mdscreen:text-16 mdscreen:leading-[20px] text-black-500">
                            {item?.title}
                          </h6>
                          <div>{item?.excerpt && parse(item?.excerpt)}</div>
                        </div>
                        <div className="btn-custom flex flex-wrap items-center space-x-8 mt-15">
                          <span className="button btn-red-border">Read More</span>
                        </div>
                      </div>
                    </div>
                  </Link>
                </SwiperSlide>
              ))}
          </Swiper>
          <div className="swiper-arrow swiper-arrow-red">
            <a
              className={`swiper-button-next-${uniqueId} button-next cursor-pointer absolute top-[38%] right-[16vw] lgscreen:right-[6vw] mdscreen:right-[50px] translate-y-minus_50 z-9`}
            >
              <img src="/images/white-arrow.svg" loading="lazy" alt="Next" />
            </a>
          </div>
        </motion.div>
      </div>
    </section>
  );
};

export default RelatedBlog;
export const RelatedBlogFragment = graphql`
  fragment RelatedBlogFragment on WpPage_Pagecontent_PageContent_RelatedBlog {
    id
    extraClass
    heading
    description
    blog {
      ... on WpPost {
        id
        title
        uri
        excerpt
        featuredImage {
          node {
            altText
            gatsbyImage(
              formats: [AUTO, WEBP, AVIF]
              placeholder: BLURRED
              width: 400
            )
          }
        }
      }
    }
  }
`;

export const TeamSingleRelatedBlogFragment = graphql`
  fragment TeamSingleRelatedBlogFragment on WpTeam_Teamcontent_TeamContent_RelatedBlog {
    id
    extraClass
    heading
    description
    blog {
      ... on WpPost {
        id
        title
        uri
        excerpt
        featuredImage {
          node {
            altText
            gatsbyImage(
              formats: [AUTO, WEBP, AVIF]
              placeholder: BLURRED
              width: 400
            )
          }
        }
      }
    }
  }
`;

export const PostRelatedBlogFragment = graphql`
  fragment PostRelatedBlogFragment on WpPost_Postcontent_PostContent_RelatedBlog {
    id
    extraClass
    heading
    description
    blog {
      ... on WpPost {
        id
        title
        uri
        excerpt
        featuredImage {
          node {
            altText
            gatsbyImage(
              formats: [AUTO, WEBP, AVIF]
              placeholder: BLURRED
              width: 400
            )
          }
        }
      }
    }
  }
`;

export const DestinationRelatedBlogFragment = graphql`
  fragment DestinationRelatedBlogFragment on WpDestination_Destinationcontent_DestinationContent_RelatedBlog {
    id
    extraClass
    heading
    description
    blog {
      ... on WpPost {
        id
        title
        uri
        excerpt
        featuredImage {
          node {
            altText
            gatsbyImage(
              formats: [AUTO, WEBP, AVIF]
              placeholder: BLURRED
              width: 400
            )
          }
        }
      }
    }
  }
`;

export const TravelStyleSingleRelatedBlogFragment = graphql`
  fragment TravelStyleSingleRelatedBlogFragment on WpTravel_Travelstylecontent_TravelStyle_RelatedBlog {
    id
    extraClass
    heading
    description
    blog {
      ... on WpPost {
        id
        title
        uri
        excerpt
        featuredImage {
          node {
            altText
            gatsbyImage(
              formats: [AUTO, WEBP, AVIF]
              placeholder: BLURRED
              width: 400
            )
          }
        }
      }
    }
  }
`;
